<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'PushMessage',
              }"
              >LINE 主控台</b-breadcrumb-item
            >
            <b-breadcrumb-item active>訊息範本</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-2 mb-xl-2 justify-content-between">
        <div class="col-12 col-xl-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">訊息範本</h4>
        </div>
        <div
          class="
            col-12 col-xl-6
            d-flex
            justify-content-start justify-content-xl-end
          "
        >
          <b-button
            class="mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            :to="{
              name: 'PushMessageTemplateDetail',
              params: {
                messageID: 'create',
              },
            }"
            ><i class="fa fa-plus" aria-hidden="true"></i>新增訊息</b-button
          >
        </div>
      </div>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-table
              striped
              hover
              responsive
              :items="messages"
              :fields="fields"
              :busy="isFetch"
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #cell(title)="data">
                <router-link
                  :to="{
                    name: 'PushMessageTemplateDetail',
                    params: {
                      messageID: data.item.id,
                    },
                  }"
                >
                  <!-- <b-img
                    fluid
                    rounded="0"
                    src="https://picsum.photos/250/250/?image=54"
                    alt="Image 1"
                    class="mr-1"
                  ></b-img> -->
                  {{ data.item.title }}
                </router-link>
              </template>
              <template #cell(created_at)="data">
                <div class="mb-1">
                  {{
                    format(
                      new Date(data.item.created_at),
                      "yyyy-MM-dd HH:mm"
                    )
                  }}
                </div>
              </template>

              <template #cell(updated_at)="data">
                <div class="mb-1">
                  {{
                    format(
                      new Date(data.item.updated_at),
                      "yyyy-MM-dd HH:mm"
                    )
                  }}
                </div>
              </template>

              <template #cell(actions)="data">
                <b-button class="mr-2" variant="outline-primary" @click="publish(data.item)">群發訊息</b-button>
                <b-button class="mr-2" :to="{
                  name: 'PushMessageTemplateDetail',
                  params: {
                    messageID: data.item.id,
                  },
                }">查看</b-button>
                <b-button variant="inverse-warning" class="mr-2"
                  :to="{
                    name: 'PushMessageTemplateDetailEdit',
                    params: {
                      messageID: data.item.id,
                    },
                  }">編輯</b-button>
                <b-button variant="inverse-danger" @click="deleteMessage(data.item)">刪除</b-button>
              </template>
            </b-table>
          </div>
        </div>

        <div
          class="d-flex justify-content-center align-items-center"
          style="margin-top: 80px"
        >
          <b-pagination
            class="separated mb-0"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="groupbuyList"
            @change="handleChangePage"
          ></b-pagination>
          <div class="ml-2">
            每頁顯示數量<b-form-select
              v-model="perPage"
              :options="perPageOptions"
              style="width: 70px"
              class="ml-2"
              @change="handleChangePage"
            ></b-form-select>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import pushMessageTemplateApi from "../../../apis/push-message-template";
import { mapState } from "vuex";
import { format } from "date-fns";
import { BTable } from "bootstrap-vue";

import { zh } from "vuejs-datepicker/dist/locale";

import "bootstrap-vue/dist/bootstrap-vue.css";

import checkPermission from "@/mixins/checkPermission";

export default {
  mixins: [checkPermission],
  components: {
    BTable,
  },
  data() {
    return {
      zh,
      isFetch: false,
      messages: [],
      sortBy: "created_at",
      sortDesc: false,
      fields: [
        {
          key: "title",
          label: "標題",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "created_at",
          label: "建立時間",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "編輯時間",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "actions",
          label: "操作",
          thClass: "text-left",
        },
      ],
      perPageOptions: [10, 50, 100],
      currentTabIndex: 0,
      perPage: 10,
      currentPage: 1,
      total: 0,
      query: {
        start_at: "",
        end_at: "",
      },
    };
  },

  computed: {
    currentTabStatus() {
      return this.tabs[this.currentTabIndex].status;
    },
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },

  mounted() {
    this.fetchMessages();
  },

  methods: {
    format,
    async fetchMessages() {
      this.isFetch = true;

      try {
        const { data } = await pushMessageTemplateApi.getPushMessageTemplates();

        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.messages = data.data;
      } catch (error) {
        console.log("error", error);
      }
      this.isFetch = false;
    },

    handleChangePage() {
      this.$nextTick(() => {
        this.fetchMessages();
      });
    },

    handleSort(sortBy) {
      if (this.sortBy === sortBy) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = sortBy;
      }
      this.currentPage = 1;
      this.fetchMessages();
    },
    async publish(message) {
      this.$router.push({
        name: 'PushMessageDetail',
        params: {
          messageID: 'create',
          templateID: message.id
        },
      });
    },

    async deleteMessage(message) {
      this.isFetch = true;

      try {
        const params = {
          title: message.title,
          status: message.status,
        };

        console.log(params);

         await pushMessageTemplateApi.removePushMessageTemplate(
          message.id,
          params
        );
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "成功刪除 ",
        });
      } catch (error) {        
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: error,
        });
      }
      this.isFetch = false;
    },
  },
};
</script>

<style lang="scss">
</style>
