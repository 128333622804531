var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'PushMessage',
            }}},[_vm._v("LINE 主控台")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("訊息範本")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-2 mb-xl-2 justify-content-between"},[_c('div',{staticClass:"col-12 col-xl-6 d-flex align-items-center"},[_c('h4',{staticClass:"mb-2 mb-xl-0 mr-2 font-weight-bold"},[_vm._v("訊息範本")])]),_c('div',{staticClass:"\n          col-12 col-xl-6\n          d-flex\n          justify-content-start justify-content-xl-end\n        "},[_c('b-button',{staticClass:"mb-2 mb-xl-0 ml-2 py-2",attrs:{"variant":"primary","to":{
            name: 'PushMessageTemplateDetail',
            params: {
              messageID: 'create',
            },
          }}},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}}),_vm._v("新增訊息")])],1)]),_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.messages,"fields":_vm.fields,"busy":_vm.isFetch},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(title)",fn:function(data){return [_c('router-link',{attrs:{"to":{
                  name: 'PushMessageTemplateDetail',
                  params: {
                    messageID: data.item.id,
                  },
                }}},[_vm._v(" "+_vm._s(data.item.title)+" ")])]}},{key:"cell(created_at)",fn:function(data){return [_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.format( new Date(data.item.created_at), "yyyy-MM-dd HH:mm" ))+" ")])]}},{key:"cell(updated_at)",fn:function(data){return [_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.format( new Date(data.item.updated_at), "yyyy-MM-dd HH:mm" ))+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.publish(data.item)}}},[_vm._v("群發訊息")]),_c('b-button',{staticClass:"mr-2",attrs:{"to":{
                name: 'PushMessageTemplateDetail',
                params: {
                  messageID: data.item.id,
                },
              }}},[_vm._v("查看")]),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"inverse-warning","to":{
                  name: 'PushMessageTemplateDetailEdit',
                  params: {
                    messageID: data.item.id,
                  },
                }}},[_vm._v("編輯")]),_c('b-button',{attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.deleteMessage(data.item)}}},[_vm._v("刪除")])]}}])})],1)]),_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{staticClass:"separated mb-0",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage,"aria-controls":"groupbuyList"},on:{"change":_vm.handleChangePage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('div',{staticClass:"ml-2"},[_vm._v(" 每頁顯示數量"),_c('b-form-select',{staticClass:"ml-2",staticStyle:{"width":"70px"},attrs:{"options":_vm.perPageOptions},on:{"change":_vm.handleChangePage},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }